<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <p class="text-3xl font-medium text-left has-text-grey-darker">
      {{
        Array.isArray(disposicion_id)
          ? "Entregar las disposiciones"
          : pasivo ? "Recibir disposición":"Entregar la disposición"
      }}
    </p>
    <div class="mb-3 has-text-grey-darker">
      Estas a punto de entregar
      {{
        Array.isArray(disposicion_id) ? "las disposiciones" : "la disposición"
      }}, al realizar esta acción cambiara el estatus de
      {{
        Array.isArray(disposicion_id)
          ? "las disposiciones"
          : "la disposición" + disposicion_id
      }}
      de <span class="font-bold">"Autorizado"</span> a
      <span v-if="!pasivo" class="font-bold">"Entregado"</span>
      <span v-if="pasivo" class="font-bold">"Recibido"</span>
      <p class="italic">
        Es necesario llenar los siguientes campos
      </p>
    </div>
    <valid-select
      rules="required"
      label="Instrumento monetario"
      placeholder="Seleccionar..."
      v-model="instrumento_monetario"
      :data="instrumentos_monetarios"
      show="nombre"
      realValue="id"
      :expanded="true"
    />
    <!-- <ValidInput
      rules="required"
      label="Referencia"
      placeholder="Referencia"
      v-model="referencia"
      type="text"
      :expanded="true"
    /> -->
    <ValidInput
      rules="required"
      label="Referencia bancaria para pagos referenciados"
      placeholder="Referencia"
      v-model="referencia_bancaria_pago"
      type="text"
      :expanded="true"
    />
    <AsignarGarantiaFondeo
      v-if="!Array.isArray(disposicion_id)"
      :disposicion="getDisposicionDetalle"
      :cuentas="getDisposicionDetalle.cuentas_c_p"
    />
    <valid-datepicker
      rules="required"
      label="Fecha de entrega"
      placeholder="selecciona una fecha..."
      v-model="fecha_entrega"
      position="is-top-right"
    />
    <ValidAutoComplete
      label="Cuenta de banco"
      placeholder="Buscar..."
      v-model="cuentas_banco_id"
      :data="getCuentasBancarias"
      show="cuenta"
      :expanded="true"
      :custom="true"
    >
      <template v-slot:custom="slotProps">
        <div>
          <p class="has-text-grey-darker-always">
            Cuenta #{{ slotProps.option.option.cuenta }}
          </p>
          <p class="has-text-grey-darker-always">
            CLABE #{{ slotProps.option.option.CLABE }}
          </p>
          <p class="has-text-grey-darker-always">
            Institución #{{ slotProps.option.option.institucion.nombre }}
          </p>
        </div>
      </template>
    </ValidAutoComplete>
    <ValidAutoComplete
      label="Cuenta de banco del acreditado"
      placeholder="Buscar..."
      show="cuenta"
      v-if="!Array.isArray(disposicion_id)"
      v-model="persona_cuenta_banco"
      :custom="true"
      :data="getPersonaCuentasBancarias"
      :expanded="true"
    >
      <template v-slot:custom="slotProps">
        <div>
          <p class="has-text-grey-darker-always">
            Cuenta #{{ slotProps.option.option.cuenta }}
          </p>
          <p class="has-text-grey-darker-always">
            CLABE #{{ slotProps.option.option.CLABE }}
          </p>
          <p class="has-text-grey-darker-always">
            Institución #{{ slotProps.option.option.institucion.nombre }}
          </p>
        </div>
      </template>
    </ValidAutoComplete>
    <ValidInput
      label="Folio pagaré"
      placeholder="Folio"
      v-model="folio_pagare"
      type="text"
      :expanded="true"
    />
    <div v-if="!Array.isArray(disposicion_id)" class="mt-3">
      <accesorios-card
        :para-entregar="true"
        :accesorios="getDisposicionDetalle.costos_extra"
        :cuentas="getDisposicionDetalle.cuentas_c_p"
        :capital="getDisposicionDetalle.capital"
        :moneda="getDisposicionDetalle.moneda.clave"
        :iva="getDisposicionDetalle.sucursal.iva"
        @checked="setAccesorios"
      />
    </div>
    <div v-else class="mt-4 has-text-grey-darker">
      <b-checkbox size="is-medium" v-model="retencion">Retención</b-checkbox>
    </div>
    <div class="columns mt-4">
      <div class="column is-3">
        <b-button
          type="is-white"
          class="has-text-danger font-bold"
          expanded
          @click="$emit('cancelForm')"
        >
          Cancelar
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-primary"
          expanded
          class="font-bold"
          :disabled="loading"
          @click="validate().then(result => changeStatus(result))"
        >
         {{pasivo ? 'Recibir' : 'Entregar'}} disposición
        </b-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import ValidSelect from "@/components/form/ValidSelect";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import ValidInput from "@/components/form/ValidInput";
import AccesoriosCard from "@/components/cards/AccesoriosCard";
import AsignarGarantiaFondeo from "@/components/Disposicion/Extras/AsignarGarantiaFondeo";
import { mapGetters } from "vuex";
export default {
  name: "ModalEntregar",
  components: {
    ValidationObserver,
    ValidInput,
    ValidDatepicker,
    ValidAutoComplete,
    AccesoriosCard,
    ValidSelect,
    AsignarGarantiaFondeo
  },
  props: {
    disposicion_id: [Number, Array],
    status: Object,
    pasivo: Boolean,
    total: Number
  },
  data() {
    return {
      // referencia: null,
      fecha_entrega: null,
      referencia_bancaria_pago: null,
      folio_pagare: null,
      cuentas_banco_id: null,
      persona_cuenta_banco: null,
      instrumento_monetario: null,
      costos_extra_id: [],
      cuentas_c_p_id: [],
      retencion: 0,
      loading: false,
    };
  },
  methods: {
    /*
      Cambia el estatus de la disposición al indicado
      @result (Boolean): resultado de las validaciones de inputs
    */
    changeStatus(result) {
      if (!result) {
        return;
      }

      this.loading = true;

      let cuenta = this.getCuentasBancarias?.find(
        cuenta => cuenta.cuenta == this.cuentas_banco_id
      );

      let cuenta_persona = this.getPersonaCuentasBancarias?.find(
        cuenta => cuenta.cuenta == this.persona_cuenta_banco
      );

      let form = {};
      
      if (Array.isArray(this.disposicion_id)) {
        let ids = this.disposicion_id.map(disp => disp.id);
        form = {
          cuentas_banco_id: cuenta ? cuenta.id : null,
          persona_cuentas_banco_id: cuenta_persona?.id,
          autorizado_id: ids,
          pasivo: this.pasivo,
          referencia_bancaria_pago: this.referencia_bancaria_pago,
          folio_pagare: this.folio_pagare,
          fecha_entrega: this.fecha_entrega,
          instrumento_monetario_id: this.instrumento_monetario,
          total: this.total,
          retencion: this.retencion
        };
        this.$store.dispatch(
          "disposicion/changeAutorizadoEntregadoMasivo",
          form
        ).finally(() => this.loading = false);
      } else {
        form = {
          cuentas_banco_id: cuenta ? cuenta.id : null,
          persona_cuentas_banco_id: cuenta_persona?.id,
          autorizado_id: this.disposicion_id,
          pasivo: this.pasivo,
          referencia_bancaria_pago: this.referencia_bancaria_pago,
          folio_pagare: this.folio_pagare,
          fecha_entrega: this.fecha_entrega,
          instrumento_monetario_id: this.instrumento_monetario,
          costos_extra: this.costos_extra_id,
          cuentas_c_p: this.cuentas_c_p_id
        };
        this.$store.dispatch("disposicion/changeAutorizadoEntregado", form).finally(() => this.loading = false);
      }
    },
    setAccesorios(accesorios, costo_extra) {
      if (costo_extra) {
        this.costos_extra_id = accesorios;
      } else {
        this.cuentas_c_p_id = accesorios;
      }
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getInstrumentosMonetarios"]),
    ...mapGetters("empresa", ["getCuentasBancarias"]),
    ...mapGetters("personas", ["getPersonaCuentasBancarias"]),
    ...mapGetters("disposicion", ["getDisposicionDetalle"]),
    ...mapGetters(["getSofom"]),
    instrumentos_monetarios() {
      return this.getInstrumentosMonetarios
        ? this.getInstrumentosMonetarios.filter(
            instrumento => instrumento.clave != "00"
          )
        : [];
    }
  },
  created() {
    if (this.getCuentasBancarias == null) {
      this.$store.dispatch("empresa/getCuentasBancarias");
    }
    if (this.getDisposicionDetalle?.sucursal == null) {
      this.$store.dispatch(
        "disposicion/getDisposicionDetalle",
        this.$route.params.idDisposicion
      );
    }
    if (this.getPersonaCuentasBancarias == null && this.getDisposicionDetalle?.acreditado_id) {
      this.$store.dispatch("personas/getPersonaCuentasBancarias", this.getDisposicionDetalle.acreditado_id);
    }
    if (this.getInstrumentosMonetarios == null) {
      this.$store.dispatch("catalogos/getInstrumentosMonetarios");
    }
    this.fecha_entrega = this.$moment(this.getSofom.fecha_cierre).toDate();
  }
};
</script>
